const ExportIcon  = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_156_5399)">
            <path d="M15.6364 4.63636L14.3455 5.92727L12.9 4.48182V14.6364H11.1V4.48182L9.65457 5.92727L8.36366 4.63636L12 1L15.6364 4.63636ZM19.2727 9.18182V19.1818C19.2727 20.1818 18.4546 21 17.4546 21H6.54548C5.53639 21 4.72729 20.1818 4.72729 19.1818V9.18182C4.72729 8.17273 5.53639 7.36364 6.54548 7.36364H9.27275V9.18182H6.54548V19.1818H17.4546V9.18182H14.7273V7.36364H17.4546C18.4546 7.36364 19.2727 8.17273 19.2727 9.18182Z" fill="#74716F"/>
            </g>
            <defs>
            <clipPath id="clip0_156_5399">
            <rect width="24" height="24" fill="white"/>
            </clipPath>
            </defs>
        </svg>

    )
}

export default ExportIcon
