import axios from 'axios'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useGetBulkIdsKey } from './Components/ControlCenter'

type Action = {
  name: string
  runtime?: number
  intensity?: string
  bay: number
}

export type MessagePayload = {
  deviceIds?: string[]
  zoneIds?: string[]
  action: Action
}

export const useGroupTurnOn = () => {
  const queryClient = useQueryClient()
  const mutation = useMutation({
    mutationFn: (payload: MessagePayload) => {
      return axios.post('https://bulk.stg.trypura.io/bulk/actions', payload)
    },
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: useGetBulkIdsKey })
    },
    onError: () => {
      console.log('failed')
    },
  })
  return mutation
}
