import React from 'react';
import Box from '@mui/material/Box';
import PuraLogo from '../Icons/PuraLogo';
import { Typography } from '@mui/material';

export default function Inventory() {
    return (
        <Box
            height={'100vh'}
            width={'100%'}
            display="flex"
            pt={'64px'}
        >
            <Typography variant="h1"
                ml={'48px'}
                sx={{
                    color: '#1D1B1B',
                    fontFamily: "Apercu Pro",
                    fontSize: '24px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '32px',
                }}
            >
                Inventory
            </Typography>
        </Box>
    );
}