const PlayIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_33_2610)">
            <path d="M10 8.64L15.27 12L10 15.36V8.64ZM8 5V19L19 12L8 5Z" fill="#4F4C4A"/>
            </g>
            <defs>
            <clipPath id="clip0_33_2610">
            <rect width="24" height="24" fill="white"/>
            </clipPath>
            </defs>
        </svg>
    )
}

export default PlayIcon
