
const HomeIcon  = () => {
    return (
    <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10 0L0 9.42875H2.72727V18H9.09091V12.2858H10.9091V18H17.2727V9.42875H20L10 0ZM10 2.56319L15.4545 7.70631V8.47639V16.0953H12.7273V10.3811H7.27273V16.0953H4.54545V7.70631L10 2.56319Z" fill="#1D1B1B"/>
    </svg>
    )
}

export default HomeIcon
