import React from 'react';
import Box from '@mui/material/Box';
import PuraLogo from '../Icons/PuraLogo';
import CompanyLogo from '../Icons/CompanyLogo';
import DividerLine from '../Icons/DividerLine';
import '../Styles/SideBar.css';
import SideBarButton from './SideBarButton';
import HomeIcon from '../Icons/HomeIcon';
import SettingIcon from '../Icons/SettingsIcon';
import InventoryIcon from '../Icons/InventoryIcon';
import { useLocation } from 'react-router-dom';


interface SideBarProps {
  toggleClicked: () => void;
  checked: boolean;
}
export default function SideBar({ toggleClicked, checked }: SideBarProps) {  const location = useLocation();
  const path = location.pathname;

  return (
    <Box
    height={'100vh'}
    width={'100%'}
    display="flex"
    pt={'70px'}
    justifyContent={'flex-start'}
    sx={{ backgroundColor: '#F8F8F4', flexDirection: 'column',
     ...(checked ? { width: '296px' } : { width: '136px' })
      }}  >
    <div className='logo-container'>
      {checked  && (<><CompanyLogo height='40' width='25' /> <DividerLine /></>)}<span onClick={toggleClicked}><PuraLogo /></span>
    </div>
    <div className={`sidebar-buttons ${!checked ? 'closed' : ''}`}>      <SideBarButton icon={<HomeIcon />} title={`${checked ? 'Control Center' : ''}`} active={path==='/'} path='/'/>
      <SideBarButton icon={<InventoryIcon />} title={`${checked ? 'Inventory' : ''}`} active={path==='/inventory'} path='inventory'/>
      <SideBarButton icon={<InventoryIcon />} title={`${checked ? 'Orders' : ''}`} active={path==='/orders'} path='orders'/>
    </div>

    <div className={`setting-button ${!checked ? 'closed' : ''}`}>
      <SideBarButton icon={<SettingIcon />} title={`${checked ? 'Settings' : ''}`} active={path==='/settings'} path='settings'/>
    </div>
  </Box>
  );
}