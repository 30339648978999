const EditCalendarIcon  = () => {
    return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_33_2677)">
        <path d="M5.52381 10.0952H16.1905V11.619H17.7143V7.04762C17.7143 6.20952 17.0286 5.52381 16.1905 5.52381H15.4286V4H13.9048V5.52381H7.80952V4H6.28571V5.52381H5.52381C4.6781 5.52381 4.00762 6.20952 4.00762 7.04762L4 17.7143C4 18.5524 4.6781 19.2381 5.52381 19.2381H10.8571V17.7143H5.52381V10.0952ZM5.52381 7.04762H16.1905V8.57143H5.52381V7.04762ZM19.1162 14.88L18.5752 15.421L16.96 13.8057L17.501 13.2648C17.7981 12.9676 18.2781 12.9676 18.5752 13.2648L19.1162 13.8057C19.4133 14.1029 19.4133 14.5829 19.1162 14.88ZM16.419 14.3467L18.0343 15.9619L13.9962 20H12.381V18.3848L16.419 14.3467Z" fill="#4F4C4A"/>
        </g>
        <defs>
        <clipPath id="clip0_33_2677">
        <rect width="24" height="24" fill="white"/>
        </clipPath>
        </defs>
    </svg> 
    )
}

export default EditCalendarIcon