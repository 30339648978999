const InventoryIcon  = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_15_318)">
        <path d="M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM19 19H5V5H19V19Z" fill="#4F4C4A"/>
        <path d="M9 12H7V17H9V12Z" fill="#4F4C4A"/>
        <path d="M17 7H15V17H17V7Z" fill="#4F4C4A"/>
        <path d="M13 14H11V17H13V14Z" fill="#4F4C4A"/>
        <path d="M13 10H11V12H13V10Z" fill="#4F4C4A"/>
        </g>
        <defs>
        <clipPath id="clip0_15_318">
        <rect width="24" height="24" fill="white"/>
        </clipPath>
        </defs>
        </svg>
    )
}

export default InventoryIcon

