import { Button  } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export default function SideBarButton({active, title, icon, path} : {active: boolean, title: string, icon: any, path: string}) {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(path);
  };

  return (
      <Button
        tabIndex={-1}
        startIcon={icon}
        onClick={handleClick}
        sx={{
          backgroundColor: active ? '#EEEAE5' : '#F8F8F4',
          borderRadius: '24px',
          justifyContent: 'flex-start',
          padding: '16px 50px',
          overflow: 'hidden',
        }}
      >
        <span className='button-text'>
          {title}
        </span>
      </Button>

  );
}