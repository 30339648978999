import Box from '@mui/material/Box'
import { Typography } from '@mui/material'
import SignalStrong from '../../Icons/SignalStrong'

export default function WifiCard({ signal, network }: { signal: string; network: string }) {
  return (
    <Box
      p={'16px'}
      sx={{
        backgroundColor: '#fff',
        borderRadius: '16px',
        fontFamily: 'Apercu',
        height: '34%',
      }}>
      <Typography
        variant="h2"
        sx={{
          color: '#000',
          fontSize: '14px',
          fontWeight: 'bold',
          lineHeight: '24px',
          textAlign: 'left',
          fontFamily: 'Apercu',
        }}>
        Wi-Fi
      </Typography>
      <Typography
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          color: '#4F4C4A',
          textTransform: 'capitalize',
          alignItems: 'center',
          marginTop: '8px',
        }}>
        <span>Signal:</span>
        {signal === 'strong' && (
          <span style={{ color: '#15803D' }}>
            {' '}
            <SignalStrong /> {signal}
          </span>
        )}
      </Typography>
      <Typography
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          color: '#4F4C4A',
          textTransform: 'capitalize',
          marginTop: '8px',
        }}>
        <span>Network:</span>
        <span style={{ color: '#74716F' }}>{network}</span>
      </Typography>
    </Box>
  )
}
