const VialHalf = ({ height, width }: { height?: string; width?: string }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect
        x="5.05264"
        y="9.84204"
        width="5.89474"
        height="5.15789"
        fill="#EAB308"
      />
      <path
        d="M11.4474 14.5V7.83146C11.4474 7.27918 10.9997 6.83146 10.4474 6.83146H10.3717V6.5927C10.6249 6.48262 10.802 6.23026 10.802 5.93655V5.07865C10.802 4.52637 10.3543 4.07865 9.80197 4.07865H9.76932V1.5C9.76932 0.947716 9.3216 0.5 8.76932 0.5H7.31676C6.76448 0.5 6.31676 0.947715 6.31676 1.5L6.31676 4.07865H6.28411C5.73182 4.07865 5.28411 4.52637 5.28411 5.07865V5.91504C5.28411 6.22908 5.48063 6.49724 5.75741 6.60322V6.83146H5.55264C5.00036 6.83146 4.55264 7.27918 4.55264 7.83146V14.5C4.55264 15.0523 5.00036 15.5 5.55264 15.5H10.4474C10.9997 15.5 11.4474 15.0523 11.4474 14.5Z"
        stroke="#EAB308"
        stroke-linecap="square"
      />
    </svg>
  )
}

export default VialHalf
