const CalendarIcon  = ({color = '#4F4C4A' }: {color?: string}) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_33_2661)">
            <path d="M18.0909 5.45455H17.3636V4H15.9091V5.45455H8.63636V4H7.18182V5.45455H6.45455C5.65455 5.45455 5 6.10909 5 6.90909V18.5455C5 19.3455 5.65455 20 6.45455 20H18.0909C18.8909 20 19.5455 19.3455 19.5455 18.5455V6.90909C19.5455 6.10909 18.8909 5.45455 18.0909 5.45455ZM18.0909 18.5455H6.45455V10.5455H18.0909V18.5455ZM18.0909 9.09091H6.45455V6.90909H18.0909V9.09091Z" fill={color}/>
            </g>
            <defs>
            <clipPath id="clip0_33_2661">
            <rect width="24" height="24" fill="white"/>
            </clipPath>
            </defs>
        </svg>
    )
}

export default CalendarIcon