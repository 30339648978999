import Box from '@mui/material/Box'
import { Grid, Typography } from '@mui/material'
import '../../Styles/DropDownCards.css'
import StartStopButton from './StartStopButton'
import IntensityButton from './IntensityButton'
import CalendarIcon from '../../Icons/CalendarIcon'
import LightIcon from '../../Icons/LightIcon'

export default function ControlDiffuserCard(data: any) {
    const bay1data = data?.data?.bay1
    const bay2data = data?.data?.bay2
    const bay1Image = bay1data?.fragrance?.bgScentImgUrl
    const bay1ImageAlt = bay1data?.fragrance?.name
    const bay2Image = bay2data?.fragrance?.bgScentImgUrl
    const bay2ImageAlt = bay2data?.fragrance?.name
    const activeBay = data?.data?.deviceDefaults?.bay
    const deviceId = data?.data?.deviceId

    const bay1Intensity = data?.data?.deviceDefaults?.bay1Intensity
    const bay2Intensity = data?.data?.deviceDefaults?.bay2Intensity
    
  return (
    <Box
      p={'16px'}
      sx={{
        backgroundColor: '#Fff',
        borderRadius: '16px',
        fontFamily: 'Apercu',
        height: '100%',
      }}>
      <Typography
        variant="h2"
        sx={{
          color: '#000',
          fontSize: '14px',
          fontWeight: 'bold',
          lineHeight: '24px',
          textAlign: 'left',
          fontFamily: 'Apercu',
          display: 'flex',
          justifyContent: 'space-between',
        }}>
        Control Diffuser
        <span>
          <CalendarIcon color="#74716F" />
          <LightIcon color="#74716F" />
        </span>
      </Typography>

      <Grid
        container
        spacing={2}
        p={'16px'}>
        <Grid
          item
          xs={6}>
          <img src={bay1Image} alt={bay1ImageAlt} className="bay-image"/>
          <p className="bay-title">Bay 1</p>
          <StartStopButton active={activeBay === 1} deviceId={deviceId} bay={1} />
          <IntensityButton intensity={bay1Intensity} />
        </Grid>
        <Grid
          item
          xs={6}>
            <img src={bay2Image} alt={bay2ImageAlt} className="bay-image"/>
          <p className="bay-title">Bay 2</p>
          <StartStopButton active={activeBay === 2} deviceId={deviceId} bay={2} />
          <IntensityButton intensity={bay1Intensity} />
        </Grid>
      </Grid>
    </Box>
  )
}
