const IntensityMedium  = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2 8L2 14" stroke="#4F4C4A" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/>
        <path d="M8 6L8 14.2499" stroke="#4F4C4A" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/>
        <path d="M14 2L14 13.9999" stroke="#CEC6C2" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/>
        </svg>
    )
}

export default IntensityMedium
