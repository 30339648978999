import Box from '@mui/material/Box'
import Pura4Image from '../../Icons/Pura4'
import { Typography } from '@mui/material'
import PuraMini from '../../Icons/PuraMini'
import PuraPlus from '../../Icons/PuraPlus'
import Pura3 from '../../Icons/Pura3'

export default function DeviceCard({ deviceType, lastReported }: { deviceType?: string; lastReported: string }) {
  return (
    <Box
      p={'16px'}
      sx={{
        backgroundColor: '#Fff',
        borderRadius: '16px',
        fontFamily: 'Apercu',
        height: '100%',
      }}>
      <Typography
        variant="h2"
        sx={{
          color: '#000',
          fontSize: '14px',
          fontWeight: 'bold',
          lineHeight: '24px',
          textAlign: 'left',
          fontFamily: 'Apercu',
        }}>
        {deviceType}
      </Typography>
      {deviceType === 'Pura 4' && <Pura4Image />}
      {deviceType === 'Pura 3' && <Pura3 />}
      {deviceType === 'Pura Mini' && <PuraMini />}
      {deviceType === 'Pura Plus' && <PuraPlus />}

      <Typography
        variant="h3"
        sx={{
          color: '#4F4C4A',
          fontSize: '14px',
          textAlign: 'left',
          padding: '60px 0 6px',
          fontFamily: 'Apercu',
        }}>
        Last reported:
      </Typography>
      <Typography
        pb={'4px'}
        sx={{
          color: '#4F4C4A',
          fontSize: '14px',
          textAlign: 'left',
          fontFamily: 'Apercu Pro',
        }}>
        {lastReported}
      </Typography>
    </Box>
  )
}
