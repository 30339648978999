const VialFull = ({ height = '16', width ='16' }: { height?: string; width?: string }) => {
  return (
    <svg
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect
        x="5.05264"
        y="7.12915"
        width="5.89474"
        height="8.98026"
        fill="#15803D"
      />
      <path
        d="M11.4474 15.6094V8.82101C11.4474 8.26873 10.9997 7.82101 10.4474 7.82101L10.3717 7.82101V7.56382C10.6249 7.45374 10.802 7.20138 10.802 6.90767V6.02519C10.802 5.47291 10.3543 5.02519 9.80197 5.02519H9.76932V2.39063C9.76932 1.83834 9.3216 1.39062 8.76932 1.39062H7.31676C6.76448 1.39062 6.31676 1.83834 6.31676 2.39062L6.31676 5.02519H6.28411C5.73182 5.02519 5.28411 5.47291 5.28411 6.02519V6.88616C5.28411 7.2002 5.48063 7.46836 5.75741 7.57434V7.82101H5.55264C5.00036 7.82101 4.55264 8.26873 4.55264 8.82101V15.6094C4.55264 16.1617 5.00036 16.6094 5.55264 16.6094H10.4474C10.9997 16.6094 11.4474 16.1617 11.4474 15.6094Z"
        stroke="#15803D"
        stroke-linecap="square"
      />
    </svg>
  )
}

export default VialFull
