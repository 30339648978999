
const PuraLogo  = () => {
    return (
        <svg width="69" height="19" viewBox="0 0 69 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_2_92)">
        <g opacity="0.5">
        <path d="M31.1852 0.0190991C30.2938 0.00955617 29.6598 0.641299 29.6579 1.55551C29.6542 3.89163 29.6617 6.22583 29.6561 8.56003C29.6485 11.358 27.1163 13.4708 24.3948 12.9555C22.2846 12.5566 20.7933 10.7129 20.7876 8.47987C20.7819 6.17239 20.7857 3.86681 20.7876 1.55933C20.7876 1.25777 20.7365 0.975302 20.5757 0.717643C20.2123 0.137432 19.5367 -0.122136 18.8781 0.0649052C18.2497 0.242404 17.8239 0.822615 17.822 1.51543C17.8201 3.88017 17.7861 6.24301 17.8296 8.60775C17.8845 11.5603 19.2509 13.7857 21.8191 15.1523C24.3551 16.5017 26.9081 16.3681 29.3495 14.7954C29.5955 14.637 29.622 14.6446 29.7072 14.9252C29.8756 15.4863 30.2219 15.8737 30.8048 15.9921C31.8003 16.1982 32.633 15.513 32.6368 14.4786C32.6444 12.3333 32.6387 10.1881 32.6387 8.04281C32.6387 6.77932 32.6387 5.51774 32.6387 4.25426C32.6387 3.33432 32.6482 2.41247 32.6349 1.49253C32.6236 0.652751 31.9971 0.0286421 31.1871 0.0190991H31.1852Z" fill="#1D1B1B"/>
        <path d="M7.98461 1.23902e-05C3.65638 -0.00762196 0.14194 3.51372 0.0397434 7.83477C-0.00378499 9.63648 0.0321732 11.442 0.0321732 13.2456H0.0227105C0.0227105 14.7649 0.0227105 14.8698 0.0227105 16.3891C0.0227105 16.8166 0.0113553 17.2441 0.0378508 17.6697C0.0757016 18.2747 0.49206 18.7653 1.06739 18.9218C2.08368 19.2004 2.9921 18.519 2.99778 17.4579C3.00535 16.0913 2.99778 16.1371 3.00156 14.7706C3.00156 14.6618 2.95614 14.5263 3.07726 14.4576C3.20406 14.387 3.28355 14.511 3.3725 14.5721C5.39373 16.0093 7.62882 16.37 9.9907 15.7745C14.1997 14.7152 16.6997 10.4037 15.7118 6.0865C15.0021 2.98695 11.9041 0.00764674 7.98461 1.23902e-05ZM8.02436 13.0261C5.23286 13.028 3.00724 10.816 3.00156 8.03135C2.99778 5.2639 5.22908 3.00604 7.97136 3.00604C10.6796 3.00604 12.9374 5.2639 12.9393 7.97791C12.9412 10.7492 10.7269 13.0223 8.02436 13.0242V13.0261Z" fill="#1D1B1B"/>
        <path d="M46.3993 0.885597C46.0359 0.58404 45.6158 0.389364 45.1635 0.284392C42.7316 -0.28055 40.434 -0.0190736 38.339 1.44672C38.0791 1.62867 37.9277 1.56633 37.8848 1.25968C37.8772 1.20433 37.864 1.14898 37.8469 1.09363C37.6407 0.356918 36.9574 -0.0744226 36.1664 0.0324583C35.4737 0.125979 34.9362 0.748179 34.9343 1.47726C34.9305 3.66069 34.9343 5.84411 34.9343 8.02754C34.9343 10.211 34.9362 12.3371 34.9324 14.4919C34.9324 14.8049 34.9911 15.0931 35.1652 15.3546C35.5418 15.9176 36.2137 16.1619 36.8515 15.9711C37.4798 15.7821 37.898 15.2057 37.898 14.511C37.8999 12.3562 37.8924 10.2014 37.8999 8.04662C37.9094 5.00815 40.4511 2.72166 43.447 3.03849C43.9769 3.09383 44.4784 3.23889 44.9742 3.42784C45.6593 3.68741 46.3747 3.40684 46.7419 2.75792C47.1052 2.11473 46.9652 1.35511 46.3993 0.885597Z" fill="#1D1B1B"/>
        <path d="M62.7641 8.07717C62.8739 3.47557 58.7368 -0.715694 53.4566 0.106908C49.7888 0.679484 45.8032 4.51002 47.092 9.93805C48.3922 15.4119 54.7246 17.8396 59.3897 14.5645C59.7266 14.3278 59.7417 14.3335 59.7985 14.7496C59.8836 15.368 60.3549 15.8795 60.9548 15.9978C61.9295 16.1906 62.7489 15.513 62.7584 14.4824C62.7698 13.3258 62.7603 12.1673 62.7603 11.0088C62.7603 10.0316 62.7395 9.05246 62.7641 8.07526V8.07717ZM54.7265 13.0281C52.022 13.0204 49.8362 10.7511 49.8418 7.95693C49.8475 5.23719 52.1034 2.99651 54.8268 3.00796C57.5464 3.01941 59.7871 5.29063 59.7795 8.02946C59.7739 10.8122 57.5255 13.0376 54.7265 13.0281Z" fill="#1D1B1B"/>
        </g>
        <path d="M31.1682 0.0343579C30.2768 0.024815 29.6428 0.656558 29.6409 1.57077C29.6371 3.90688 29.6447 6.24109 29.639 8.57529C29.6314 11.3733 27.0992 13.4861 24.3777 12.9708C22.2676 12.5719 20.7762 10.7282 20.7706 8.49513C20.7649 6.18765 20.7687 3.88207 20.7706 1.57459C20.7706 1.27303 20.7195 0.990561 20.5586 0.732901C20.1952 0.15269 19.5196 -0.106878 18.861 0.080164C18.2327 0.257663 17.8069 0.837874 17.805 1.53069C17.8031 3.89543 17.769 6.25827 17.8125 8.62301C17.8674 11.5756 19.2338 13.801 21.802 15.1676C24.338 16.5169 26.891 16.3833 29.3324 14.8106C29.5784 14.6522 29.6049 14.6599 29.6901 14.9404C29.8585 15.5016 30.2049 15.889 30.7878 16.0073C31.7832 16.2135 32.616 15.5283 32.6197 14.4938C32.6273 12.3486 32.6216 10.2033 32.6216 8.05806C32.6216 6.79458 32.6216 5.533 32.6216 4.26952C32.6216 3.34958 32.6311 2.42773 32.6178 1.50779C32.6065 0.668009 31.9801 0.0439009 31.1701 0.0343579H31.1682Z" fill="#1D1B1B"/>
        <path d="M7.9656 0.0152781C3.63926 0.00573519 0.12482 3.52899 0.0207309 7.84812C-0.0227974 9.64983 0.0131608 11.4554 0.0131608 13.259H0.00369808C0.00369808 14.7782 0.00369808 14.8832 0.00369808 16.4024C0.00369808 16.8299 -0.00765714 17.2575 0.0188384 17.6831C0.0566891 18.2881 0.473047 18.7786 1.04838 18.9351C2.06467 19.2138 2.97309 18.5324 2.97877 17.4712C2.98634 16.1047 2.97877 16.1505 2.98255 14.7839C2.98255 14.6751 2.93713 14.5396 3.05825 14.4709C3.18505 14.4003 3.26454 14.5244 3.35349 14.5854C5.37472 16.0226 7.6098 16.3833 9.97169 15.7879C14.1807 14.7286 16.6807 10.4171 15.6928 6.09986C14.985 3.00222 11.8869 0.0229125 7.9656 0.0152781ZM8.00534 13.0414C5.21385 13.0433 2.98823 10.8312 2.98255 8.04662C2.97877 5.27916 5.21007 3.0213 7.95235 3.0213C10.6606 3.0213 12.9184 5.27917 12.9203 7.99318C12.9222 10.7644 10.7079 13.0376 8.00534 13.0395V13.0414Z" fill="#1D1B1B"/>
        <path d="M46.3823 0.900856C46.0189 0.599299 45.5987 0.404623 45.1464 0.299651C42.7145 -0.265291 40.417 -0.00381481 38.3219 1.46198C38.062 1.64393 37.9106 1.58159 37.8677 1.27494C37.8602 1.21959 37.8469 1.16424 37.8299 1.10889C37.6236 0.372177 36.9404 -0.0591639 36.1493 0.0477171C35.4566 0.141238 34.9192 0.763438 34.9173 1.49252C34.9135 3.67594 34.9173 5.85937 34.9173 8.04279C34.9173 10.2262 34.9192 12.3524 34.9154 14.5072C34.9154 14.8202 34.974 15.1084 35.1482 15.3699C35.5248 15.9329 36.1966 16.1772 36.8344 15.9863C37.4627 15.7974 37.881 15.221 37.881 14.5263C37.8829 12.3715 37.8753 10.2167 37.8829 8.06188C37.8923 5.02341 40.434 2.73692 43.4299 3.05374C43.9598 3.10909 44.4613 3.25415 44.9572 3.4431C45.6423 3.70266 46.3577 3.4221 46.7248 2.77318C47.0882 2.12999 46.9481 1.37037 46.3823 0.900856Z" fill="#1D1B1B"/>
        <path d="M62.7451 8.09243C62.8549 3.49083 58.7178 -0.700435 53.4376 0.122167C49.7699 0.694743 45.7842 4.52528 47.073 9.95331C48.3732 15.4271 54.7056 17.8549 59.3707 14.5797C59.7076 14.3431 59.7227 14.3488 59.7795 14.7649C59.8647 15.3832 60.3359 15.8947 60.9359 16.0131C61.9105 16.2058 62.73 15.5283 62.7395 14.4977C62.7508 13.3411 62.7413 12.1825 62.7413 11.024C62.7413 10.0468 62.7205 9.06772 62.7451 8.09052V8.09243ZM54.7094 13.0433C52.005 13.0357 49.8191 10.7664 49.8248 7.97219C49.8305 5.25245 52.0864 3.01177 54.8097 3.02322C57.5293 3.03467 59.7701 5.30589 59.7625 8.04472C59.7568 10.8274 57.5085 13.0529 54.7094 13.0433Z" fill="#1D1B1B"/>
        <path d="M31.2079 0.0343579C30.3165 0.024815 29.6825 0.656558 29.6807 1.57077C29.6769 3.90688 29.6844 6.24109 29.6788 8.57529C29.6712 11.3733 27.139 13.4861 24.4175 12.9708C22.3073 12.5719 20.816 10.7282 20.8103 8.49513C20.8047 6.18765 20.8084 3.88207 20.8103 1.57459C20.8103 1.27303 20.7592 0.990561 20.5984 0.732901C20.235 0.15269 19.5594 -0.106878 18.9008 0.080164C18.2724 0.257663 17.8466 0.837874 17.8447 1.53069C17.8428 3.89543 17.8088 6.25827 17.8523 8.62301C17.9072 11.5756 19.2736 13.801 21.8418 15.1676C24.3778 16.5169 26.9308 16.3833 29.3722 14.8106C29.6182 14.6522 29.6447 14.6599 29.7299 14.9404C29.8983 15.5016 30.2446 15.889 30.8275 16.0073C31.823 16.2135 32.6557 15.5283 32.6595 14.4938C32.6671 12.3486 32.6614 10.2033 32.6614 8.05806C32.6614 6.79458 32.6614 5.533 32.6614 4.26952C32.6614 3.34958 32.6709 2.42773 32.6576 1.50779C32.6463 0.668009 32.0198 0.0439009 31.2098 0.0343579H31.2079Z" fill="#1D1B1B"/>
        <path d="M8.00542 0.0152781C3.67909 0.00573519 0.164646 3.52899 0.0605563 7.84812C0.017028 9.64983 0.0529862 11.4554 0.0529862 13.259H0.0435235C0.0435235 14.7782 0.0435235 14.8832 0.0435235 16.4024C0.0435235 16.8299 0.0321683 17.2575 0.0586638 17.6831C0.0965145 18.2881 0.512873 18.7786 1.0882 18.9351C2.1045 19.2138 3.01291 18.5324 3.01859 17.4712C3.02616 16.1047 3.01859 16.1505 3.02238 14.7839C3.02238 14.6751 2.97696 14.5396 3.09808 14.4709C3.22488 14.4003 3.30436 14.5244 3.39331 14.5854C5.41454 16.0226 7.64963 16.3833 10.0115 15.7879C14.2205 14.7286 16.7206 10.4171 15.7327 6.09986C15.0248 3.00031 11.9268 0.0229125 8.00542 0.0152781ZM8.04706 13.0414C5.25557 13.0433 3.02995 10.8312 3.02427 8.04662C3.02048 5.27916 5.25178 3.0213 7.99407 3.0213C10.7023 3.0213 12.9601 5.27917 12.962 7.99318C12.9639 10.7644 10.7496 13.0376 8.04706 13.0395V13.0414Z" fill="#1D1B1B"/>
        <path d="M46.422 0.900856C46.0587 0.599299 45.6385 0.404623 45.1862 0.299651C42.7543 -0.265291 40.4567 -0.00381481 38.3617 1.46198C38.1018 1.64393 37.9504 1.58159 37.9075 1.27494C37.8999 1.21959 37.8867 1.16424 37.8696 1.10889C37.6634 0.372177 36.9802 -0.0591639 36.1891 0.0477171C35.4964 0.141238 34.9589 0.763438 34.957 1.49252C34.9532 3.67594 34.957 5.85937 34.957 8.04279C34.957 10.2262 34.9589 12.3524 34.9551 14.5072C34.9551 14.8202 35.0138 15.1084 35.1879 15.3699C35.5645 15.9329 36.2364 16.1772 36.8742 15.9863C37.5025 15.7974 37.9207 15.221 37.9207 14.5263C37.9226 12.3715 37.9151 10.2167 37.9226 8.06188C37.9321 5.02341 40.4738 2.73692 43.4697 3.05374C43.9996 3.10909 44.5011 3.25415 44.9969 3.4431C45.682 3.70266 46.3974 3.4221 46.7646 2.77318C47.1279 2.12999 46.9879 1.37037 46.422 0.900856Z" fill="#1D1B1B"/>
        <path d="M62.7849 8.09243C62.8947 3.49083 58.7576 -0.700435 53.4774 0.122167C49.8097 0.694743 45.824 4.52528 47.1128 9.95331C48.413 15.4271 54.7454 17.8549 59.4105 14.5797C59.7474 14.3431 59.7625 14.3488 59.8193 14.7649C59.9044 15.3832 60.3757 15.8947 60.9756 16.0131C61.9503 16.2058 62.7698 15.5283 62.7792 14.4977C62.7906 13.3411 62.7811 12.1825 62.7811 11.024C62.7811 10.0468 62.7603 9.06772 62.7849 8.09052V8.09243ZM54.7492 13.0433C52.0447 13.0357 49.8589 10.7664 49.8645 7.97219C49.8702 5.25245 52.1261 3.01177 54.8495 3.02322C57.5691 3.03467 59.8098 5.30589 59.8023 8.04472C59.7966 10.8274 57.5482 13.0529 54.7492 13.0433Z" fill="#1D1B1B"/>
        <path d="M67.0299 11.9802C68.1597 11.9802 69 12.8601 69 14.0205C69 15.1809 68.1597 16.0608 67.0299 16.0608C65.9 16.0608 65.0578 15.1828 65.0578 14.0205C65.0578 12.8582 65.8981 11.9802 67.0299 11.9802ZM67.0299 15.6619C67.9685 15.6619 68.5855 14.9404 68.5855 14.0205C68.5855 13.1006 67.9667 12.3772 67.0299 12.3772C66.093 12.3772 65.4742 13.0987 65.4742 14.0205C65.4742 14.9424 66.0912 15.6619 67.0299 15.6619ZM66.8747 14.1827H66.6778V15.1351H66.305V12.9059H67.098C67.5541 12.9059 67.7831 13.1674 67.7831 13.5453C67.7831 13.8697 67.5749 14.1045 67.2948 14.1617L67.9534 15.1351H67.5276L66.8747 14.1827ZM66.6759 13.2723V13.8163H67.0923C67.3213 13.8163 67.4084 13.717 67.4084 13.5453C67.4084 13.3735 67.3213 13.2742 67.0923 13.2742H66.6759V13.2723Z" fill="#1D1B1B"/>
        </g>
        <defs>
        <clipPath id="clip0_2_92">
        <rect width="69" height="19" fill="white"/>
        </clipPath>
        </defs>
        </svg>

    )
}

export default PuraLogo
