import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarQuickFilter,
  GridRenderCellParams,
  useGridSelector,
  useGridApiContext,
  gridDetailPanelExpandedRowsContentCacheSelector,
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridFilterModel,
} from '@mui/x-data-grid-pro'
import { Button } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import VialHalf from '../Icons/VialHalf'
import VialFull from '../Icons/VialFull'
import VialEmpty from '../Icons/VialEmpty'
import VialUnknown from '../Icons/VialUnknown'

import { GridRowsProp, GridColDef, GridFilterItem } from '@mui/x-data-grid'

import { Typography } from '@mui/material'

import { DataGridPremium, GridToolbar, useGridApiRef, useKeepGroupedColumnsHidden } from '@mui/x-data-grid-premium'
import Zones from './Zones'
import ControlMenu from './ControlMenu'
import RowDropDownInfo from './RowDropDownInfo'
import { useQuery } from '@tanstack/react-query'
import VialError from '../Icons/VialError'

function CustomToolbar(selectedRows: number[]) {
  return (
    <GridToolbarContainer sx={{ marginBottom: '36px' }}>
      <ControlMenu
        active={selectedRows.length > 0}
        items={selectedRows}
      />
      <Box sx={{ flexGrow: 1 }} />
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />

      <GridToolbarExport
        slotProps={{
          tooltip: { title: 'Export data' },
        }}
      />
      <GridToolbarQuickFilter />
    </GridToolbarContainer>
  )
}

interface BulkId {
  ambientMode: boolean
  awayMode: Object
  bay1: BayDetails
  bay2: BayDetails
  bulkConfig: {
    zoneId: string
    zoneName: string
    roomName?: string
    parentId: string
  }
  connected: boolean
  controller: string
  deviceDefaults: {
    bay1Intensity: string
    bay2Intensity: string
    bay: number
    nightlight: object
  }
  deviceId: string
  deviceLocation: Object
  deviceVer: string
  disconnectReason?: string
  displayName: {
    name: string
    type: string
  }
  fwVersion: string
  hwVersion: string
  lastConnectedAt?: string
  lastDisconnectedAt?: string
  model: number
  onboardedAt: number
  ota: object
  otaVer: number
  position: number
  schedules: Array<any>
  setupComplete: boolean
  timer: {
    bay: number
    end: number
    intensity: string
    start: number
  }
  wifi: object
}

interface BayDetails {
  activeAt: number
  code: string
  fragrance?: {
    bgImgUrl: string
    bgScentImgUrl: string
    brandName: string
    description: string
    expectedLifeHours: number
    fragranceCode: string
    fragranceFormat: string
    id: string
    name: string
    placeholderColor: string
  }
  id: number
  isSmartVial: boolean
  lowFragrance: boolean
  msg: string
  vialId: string
  wearingTime?: number
}
export const useGetBulkIdsKey = ['controlCenter']
export default function ControlCenter() {
  const { data, isLoading } = useQuery<BulkId[]>({
    queryKey: useGetBulkIdsKey,
    queryFn: () =>
      axios
        .get('https://bulk.stg.trypura.io/bulk/ids')
        .then((res) => res.data)
        .catch((err) => console.log(err)),
  })

  useEffect(() => {
    const hideDiv = () => {
      const divs = document.querySelectorAll('div')
      divs.forEach((div) => {
        if (div.innerText.includes('MUI X Missing license key')) {
          div.style.bottom = '-30px'
        }
      })
    }

    const timeout = setTimeout(() => {
      hideDiv()
    }, 100)
    return () => clearTimeout(timeout)
  }, [])

  const [filter, setFilter] = useState<GridFilterModel>()

  const handleClick = (zone: string) => {
    setFilter({
      items: [
        {
          field: 'col1',
          operator: 'equals',
          value: zone,
        },
      ],
    })
  }

  const determineIfOn = (item: BulkId) => {
    if (item.connected === false) {
      return 'Offline'
    }
    if (item.controller === 'default') {
      if (item.deviceDefaults.bay !== 0) {
        return `Always On - Bay ${item.deviceDefaults.bay}`
      } else {
        return 'Inactive'
      }
    }
    if (item.controller === 'timer') {
      if (item.timer.bay !== 0) {
        return `Timer On - Bay ${item.timer.bay}`
      } else {
        return 'Inactive'
      }
    }
    if (/[0-9]/.test(item.controller)) {
      const controllerIndex = parseInt(item.controller)
      if (item.schedules.length > 0) {
        return `Schedule - Bay ${item.schedules[controllerIndex].bay}`
      } else {
        return 'Inactive'
      }
    }
  }

  const cleanDisplayName = (displayName: string) => {
    var split = displayName.split(', ')
    if (split.length == 1) {
      return displayName
    } else {
      return split[1]
    }
  }
  const getZoneName = (parentId: string) => {
    if (parentId == '426188a7-5da0-46c1-8a61-1c308164080d') {
      return 'Floor 1'
    } else if (parentId == '59d9aa27-a2ab-4c7b-9dae-49831b1a746c') {
      return 'Floor 2'
    } else {
      return 'All Hotal'
    }
  }

  const getRoomName = (displayName: string) => {
    var split = displayName.split(', ')
    return split[0]
  }

  const getVialTimeLeft = (bay: BayDetails) => {
    if (!bay) {
      return null
    }
    const wearingTime = (bay?.wearingTime ?? 0) / 60
    const expectedLifeHours = bay?.fragrance?.expectedLifeHours ?? 0
    if (wearingTime !== null) {
      return (expectedLifeHours - wearingTime) / expectedLifeHours
    } else {
      return null
    }
  }

  const getFragranceName = (bay: BayDetails) => {
    if (!bay) {
      return 'N/A'
    } else {
      return bay.fragrance?.name
    }
  }

  function transformDataToRows(data: BulkId[]) {
    return data.map((item) => ({
      id: item.deviceId,
      col1: getZoneName(item?.bulkConfig?.parentId),
      col2: getRoomName(item?.displayName?.name),
      col3: cleanDisplayName(item?.displayName?.name),
      col4: determineIfOn(item),
      col5: getVialTimeLeft(item?.bay1),
      col6: getVialTimeLeft(item?.bay2),
      col7: getFragranceName(item?.bay1),
      col8: getFragranceName(item?.bay2),
      col9: 'Off',
      col10: 'Enabled',
      data: item,
    }))
  }

  const rows = data ? transformDataToRows(data) : []
  function CustomDetailPanelToggle(props: Pick<GridRenderCellParams, 'id' | 'value'>) {
    const { id, value: isExpanded } = props
    const apiRef = useGridApiContext()
    const contentCache = useGridSelector(apiRef, gridDetailPanelExpandedRowsContentCacheSelector)

    const hasDetail = React.isValidElement(contentCache[id])

    if (isLoading) {
      return <div>LOADING</div>
    }

    return (
      <IconButton
        size="small"
        tabIndex={-1}
        disabled={!hasDetail}
        aria-label={isExpanded ? 'Close' : 'Open'}>
        <ExpandMoreIcon
          sx={{
            transform: `rotateZ(${isExpanded ? 180 : 0}deg)`,
            transition: (theme) =>
              theme.transitions.create('transform', {
                duration: theme.transitions.duration.shortest,
              }),
          }}
          fontSize="inherit"
        />
      </IconButton>
    )
  }

  const getIcon = (params: any, field: string) => {
    const fragranceLevel = params.row[field]
    if (fragranceLevel === null) {
      return <VialError />
    }
    if (fragranceLevel > 0.75) {
      return <VialFull />
    } else if (fragranceLevel > 0.15) {
      return <VialHalf />
    } else {
      return <VialEmpty />
    }
  }

  const columns: GridColDef[] = [
    { field: 'col1', headerName: 'Zone' },
    { field: 'col2', headerName: 'Room' },
    { field: 'col3', headerName: 'Diffuser name' },
    { field: 'col4', headerName: 'Status', width: 155 },
    {
      field: 'col5',
      headerName: 'Bay 1',
      width: 36,
      renderCell: (params) => {
        return getIcon(params, 'col5')
      },
    },
    {
      field: 'col6',
      headerName: 'Bay 2',
      width: 36,
      renderCell: (params) => {
        return getIcon(params, 'col6')
      },
    },
    { field: 'col7', headerName: 'Bay 1 Fragrance', width: 135 },
    { field: 'col8', headerName: 'Bay 2 Fragrance', width: 135 },
    { field: 'col9', headerName: 'Light' },
    { field: 'col10', headerName: 'Local ctrl' },
    {
      ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
      renderCell: (params) => (
        <CustomDetailPanelToggle
          id={params.id}
          value={params.value}
        />
      ),
    },
  ]

  const apiRef = useGridApiRef()
  const [selectionModel, setSelectionModel] = React.useState([])
  const handleSelectionModelChange = (newSelectionModel: any) => {
    setSelectionModel(newSelectionModel)
  }

  return (
    <Box
      width={'100%'}
      display="flex"
      pt={'64px'}>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        alignItems="flex-start">
        <Grid
          item
          xs={12}>
          <Typography
            variant="h1"
            ml={'48px'}
            sx={{
              color: '#1D1B1B',
              fontFamily: 'Apercu',
              fontSize: '24px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '32px',
              textAlign: 'left',
            }}>
            Control Center
          </Typography>
          <Zones handleClick={handleClick} />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            marginLeft: '48px',
            paddingRight: '48px',
            marginTop: '48px',
          }}>
          <DataGridPremium
            rows={rows}
            columns={columns}
            apiRef={apiRef}
            checkboxSelection={true}
            onRowSelectionModelChange={handleSelectionModelChange}
            getDetailPanelContent={({ row }) => <RowDropDownInfo row={row} />}
            filterModel={filter}
            onFilterModelChange={(newFilterModel) => setFilter(newFilterModel)}
            initialState={{
              pinnedColumns: {
                left: [GRID_CHECKBOX_SELECTION_COL_DEF.field],
                right: [GRID_DETAIL_PANEL_TOGGLE_COL_DEF.field],
              },
            }}
            sx={{
              '.MuiDataGrid-row': {
                '&:hover': {
                  backgroundColor: '#F6FBF3',
                },
                '&.Mui-selected': {
                  backgroundColor: '#F6FBF3',
                  '&:hover': {
                    backgroundColor: '#F6FBF3',
                  },
                },
              },
              '.Mui-checked': {
                color: '#4D7737 !important',
              },
              m: 1.5,
            }}
            slots={{ toolbar: () => CustomToolbar(selectionModel) }}
          />
        </Grid>
      </Grid>
    </Box>
  )
}
