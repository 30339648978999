import Box from '@mui/material/Box'
import '../Styles/SideBar.css'
import { Grid } from '@mui/material'
import DeviceCard from './DropDownCards/DeviceCard'
import ControlDiffuserCard from './DropDownCards/ControlDiffuserCard'
import WifiCard from './DropDownCards/WifiCard'
import DiffuserInfo from './DropDownCards/DiffuserInfoCard'
import QRCard from './DropDownCards/QRCard'

type DeviceType = {
  model: number
}

export default function RowDropDownInfo({ row }: { row: any }) {
  //TODO selected row should have the background color

  const getDeviceType = (row: DeviceType) => {
    const { model } = row

    if (model === 1) {
      return 'Pura 4'
    } else if (model === 3) {
      return 'Pura Plus'
    } else if (model === 4) {
      return 'Pura Mini'
    }
  }

  const dummyData = {
    device: getDeviceType(row.data),
    lastReported: '2024-07-10 / 19:20',
    bay1: 'pineapple cilantro',
    wifi: {
      signal: 'strong',
      network: 'Tommy_2.4gHz',
    },
    diffuserInfo: {
      macAddress: '00:0a:95:9d:68:16',
      hWVersion: '4.2',
      fWVersion: '7.3.4',
    },
    zoneId: 30345,
  }

  const getRoomName = (displayName: string) => {
    var split = displayName.split(', ')
    return split[0]
  }
  const getRoomZoneId = (displayName: string) => {
    var split = displayName.split(', ')
    if (split[0] == 'Room 100') {
      return '123'
    }
    if (split[0] == 'Room 101') {
      return '456'
    }
    if (split[0] == 'Room 203') {
      return '789'
    }
    return '0'
  }
  return (
    <Box
      width={'100%'}
      pt={'25px'}
      pl={'64px'}
      sx={{
        backgroundColor: '#F7F6F6',
        minHeight: '100%',
      }}>
      <Grid
        container
        spacing={2}>
        <Grid
          item
          xs={12}
          md={6}
          lg={2}>
          <DeviceCard
            deviceType={dummyData.device}
            lastReported={dummyData.lastReported}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          lg={3}>
          <ControlDiffuserCard data={row.data} />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          lg={3}>
          <WifiCard
            signal={dummyData.wifi.signal}
            network={row?.data?.wifi?.ssid}
          />
          <br />
          <DiffuserInfo
            macAddress={dummyData.diffuserInfo.macAddress}
            hWVersion={row?.data?.hwVersion}
            fWVersion={row?.data?.fwVersion}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          lg={3}>
          <QRCard
            room={getRoomName(row.data.displayName.name)}
            zoneId={row.data.bulkConfig.zoneId}
          />
        </Grid>
      </Grid>
    </Box>
  )
}
