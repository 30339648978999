import { Button  } from '@mui/material';
import IntensityStrong from '../../Icons/IntensityStrong';
import IntensityMedium from '../../Icons/IntensityMedium';
import IntensitySubtle from '../../Icons/IntensitySubtle';

export default function IntensityButton({intensity} : {intensity: string}) {
    const handleClick = () => {
    }


  return (
      <Button
        startIcon={intensity === 'strong' ? <IntensityStrong /> : 
                    intensity === 'medium' ? <IntensityMedium /> : <IntensitySubtle />}
        onClick={handleClick}
        sx={{
          backgroundColor: '#F7F6F6',
          width: '100%',
          borderRadius: '26px',
          marginTop: '9px',
        }}
      >
        <span className='button-text'>
            {intensity}
        </span>
      </Button>

  );
}