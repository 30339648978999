import React from 'react';
import './App.css';
import Grid from '@mui/material/Grid';
import SideBar from './Components/SideBar';
import ControlCenter from './Components/ControlCenter';
import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
} from "react-router-dom";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import Inventory from './Components/Inventory';
import Orders from './Components/Orders';
import Settings from './Components/Settings';


function Layout() {
  const [checked, setChecked] = React.useState(false);

  const handleChange = () => {
    setChecked((prev) => !prev);
  };
  const queryClient = new QueryClient()

  return (
    <QueryClientProvider client={queryClient}>
    <div className="App">
      <Grid container spacing={2}>
          <Grid item xs={2} sm={checked ? 2 : 1} >
            <SideBar toggleClicked={handleChange} checked={checked}/>
          </Grid>
        <Grid item xs={10} sm={checked ? 10 : 11}>
          <Outlet /> 
        </Grid>
      </Grid>
    </div>
    </QueryClientProvider>
  );
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        index: true,
        element: <ControlCenter />,
      },
      {
        path: "control-center",
        element: <ControlCenter />,
      },
      {
        path: "inventory",
        element: <Inventory />,
      },
      {
        path: "orders",
        element: <Orders />,
      },
      {
        path: "settings",
        element: <Settings />,
      },
    ],
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
