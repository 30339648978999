const StopIcon  = ({color ='#4F4C4A'} : {color?: string}) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_33_2587)">
            <path d="M16 8V16H8V8H16ZM18 6H6V18H18V6Z" fill={color}/>
            </g>
            <defs>
            <clipPath id="clip0_33_2587">
            <rect width="24" height="24" fill="white"/>
            </clipPath>
            </defs>
        </svg>
    )
}

export default StopIcon
