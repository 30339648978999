
const VialError = ({ height, width }: { height?: string; width?: string }) => {
  return (
<svg width="15" height="2" viewBox="0 0 15 2" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.888 1.066H0.952V0.0579998H6.888V1.066ZM14.7357 1.066H8.79966V0.0579998H14.7357V1.066Z" fill="#4F4C4A"/>
</svg>

  )
}

export default VialError
