import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import '../Styles/ControlCenterHeader.css'
import ZoneButton from './ZoneButton'
import axios from 'axios'
import { useQuery } from '@tanstack/react-query'
import { GridFilterModel } from '@mui/x-data-grid'

interface ZonesTabProps {
  // handleClick: React.Dispatch<React.SetStateAction<string>>
  handleClick: (x: string) => void
}

export default function ZonesTab({ handleClick }: ZonesTabProps) {
  const location = useLocation()
  const path = location.pathname

  const { data, isLoading } = useQuery({
    queryKey: ['listZones'],
    queryFn: () =>
      axios
        .get('https://bulk.stg.trypura.io/bulk/list-zones')
        .then((res) => res.data)
        .catch((err) => console.log(err)),
  })

  const [activeZone, setActiveZone] = React.useState('All Zones')

  const handleZoneClick = (zone: string) => {
    setActiveZone(zone)
    if (zone == 'All Zones') {
      zone = ''
    }
    handleClick(zone)
  }
  if (isLoading) {
    return null
  }

  return (
    <div className="zones">
      <ZoneButton
        title="All Zones"
        active={activeZone === 'All Zones'}
        handleZoneChange={handleZoneClick}
      />
      {data?.map((item: { zone_name: string; zone_id: React.Key | null | undefined }) =>
        !item.zone_name.includes('Room') ? (
          <ZoneButton
            title={item.zone_name}
            active={activeZone === item.zone_name}
            handleZoneChange={handleZoneClick}
            key={item.zone_id}
          />
        ) : null
      )}
    </div>
  )
}
