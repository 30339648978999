const SignalStrong  = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.00104 9.4508C4.32705 8.12481 6.12549 7.37988 8.00073 7.37988C9.87598 7.37988 11.6744 8.12481 13.0004 9.4508" stroke="#15803D" stroke-linecap="square" stroke-linejoin="round"/>
        <path d="M15 6.74297C14.0808 5.82365 12.9895 5.09441 11.7884 4.59687C10.5873 4.09934 9.30004 3.84326 8 3.84326C6.69996 3.84326 5.41265 4.09934 4.21159 4.59687C3.01052 5.09441 1.91922 5.82365 1 6.74297" stroke="#15803D" stroke-linecap="square" stroke-linejoin="round"/>
        <path d="M5.00006 12.1569C5.39402 11.7629 5.86173 11.4503 6.37649 11.2371C6.89125 11.0238 7.44298 10.9141 8.00016 10.9141C8.55735 10.9141 9.10907 11.0238 9.62383 11.2371C10.1386 11.4503 10.6063 11.7629 11.0003 12.1569" stroke="#15803D" stroke-linecap="square" stroke-linejoin="round"/>
        <path d="M11.0008 12.1569C10.6068 11.7629 10.1391 11.4503 9.62432 11.2371C9.10956 11.0238 8.55783 10.9141 8.00065 10.9141C7.44347 10.9141 6.89174 11.0238 6.37698 11.2371C5.86222 11.4503 5.3945 11.7629 5.00055 12.1569" stroke="#15803D" stroke-linecap="square" stroke-linejoin="round"/>
        </svg>
    )
}

export default SignalStrong
