import { Button, Snackbar  } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import PlayIcon from '../../Icons/PlayIcon';
import StopIcon from '../../Icons/StopIcon';
import { useGroupTurnOn } from '../../mutations';
import React from 'react';

export default function StartStopButton({active, deviceId, bay} : {active: boolean, deviceId: string, bay: number}) {
  const groupTurnOnMutation = useGroupTurnOn()
  const [openToast, setOpenToast] = React.useState(false);
  const [toastMessage, setToastMessage] = React.useState('');
  const [fakeActive, setActive] = React.useState(active);
  const handleOpenToast = () => {
    setOpenToast(true);
  }
  const handleCloseToast = () => {
    setOpenToast(false);
  };

  const handleStartDevices = ({bay}: {bay: number}) => {
      const deviceIDs = [deviceId]
      setActive(true)
      groupTurnOnMutation.mutate(
        {
          deviceIds: deviceIDs,
          action: {
            name: 'on', 
            intensity: 'medium', 
            bay: bay,
          },
        },
        {
            onSuccess: () => {
              handleOpenToast()
              setToastMessage(`Turned bay ${bay} on`)
            },
            onError: () => {
              handleOpenToast()
              setToastMessage(`Failed to turned bay ${bay} on`)
            },
        }
      )

  }

  const handleStopDevices = () => {
    const deviceIDs = [deviceId]
    setActive(false)
    groupTurnOnMutation.mutate(
      {
        deviceIds: deviceIDs,
        action: {
          name: 'off', 
          bay: 0,
        },
      },
      {
          onSuccess: () => {
            handleOpenToast()
            setToastMessage(`Turned bay ${bay} off`)
          },
          onError: () => {
            handleOpenToast()
            setToastMessage(`Failed to turned bay ${bay} off`)
          },
      }
    )
  }

  const handleClick = () => {
    if (active) {
      handleStopDevices()
    } else {
      handleStartDevices({bay: 1})
    }
  }

  return (
    <>
      <Button
        startIcon={fakeActive ? <StopIcon color='#fff'/> : <PlayIcon /> }
        onClick={handleClick}
        sx={{
          backgroundColor: fakeActive ? '#1D1B1B;' : '#F7F6F6',
          width: '100%',
          borderRadius: '26px',
        }}
      >
        <span className={fakeActive ? 'active-button button-text' : 'button-text'}>
          {fakeActive ? 'Stop' : 'Start'}
        </span>
      </Button>
        <Snackbar
        open={openToast}
        onClose={handleCloseToast}
        message={toastMessage}
        anchorOrigin={{  vertical: 'bottom', horizontal: 'center' }}
        key={'fade'}
        autoHideDuration={2000}
      />
      </>

  );
}