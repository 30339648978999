
export default function SideBarButton({active, title, handleZoneChange} : {active: boolean, title: string, handleZoneChange: (zone: string) => void}) {

  return (
    <button 
    className={active ? 'zone-button active-zone-button': 'zone-button'}
    onClick={() => handleZoneChange(title)}>
        {title}
    </button>
  );
}