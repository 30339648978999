const LightIcon  = ({color = "#4F4C4A" } : {color?: string}) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_33_2707)">
            <path d="M9.2 19.2C9.2 19.64 9.56 20 10 20H13.2C13.64 20 14 19.64 14 19.2V18.4H9.2V19.2ZM11.6 4C8.512 4 6 6.512 6 9.6C6 11.504 6.952 13.176 8.4 14.192V16C8.4 16.44 8.76 16.8 9.2 16.8H14C14.44 16.8 14.8 16.44 14.8 16V14.192C16.248 13.176 17.2 11.504 17.2 9.6C17.2 6.512 14.688 4 11.6 4ZM13.88 12.88L13.2 13.36V15.2H10V13.36L9.32 12.88C8.24 12.128 7.6 10.904 7.6 9.6C7.6 7.392 9.392 5.6 11.6 5.6C13.808 5.6 15.6 7.392 15.6 9.6C15.6 10.904 14.96 12.128 13.88 12.88Z" fill={color}/>
            </g>
            <defs>
            <clipPath id="clip0_33_2707">
            <rect width="24" height="24" fill="white"/>
            </clipPath>
            </defs>
        </svg>
    )
}

export default LightIcon