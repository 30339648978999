
const DividerLine  = () => {
    return (
        <svg width="2" height="27" viewBox="0 0 2 27" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 0V27" stroke="#74716F"/>
        </svg>
    )
}

export default DividerLine
