import Box from '@mui/material/Box'
import { Button, Snackbar, Typography, Fade } from '@mui/material'
import ExportIcon from '../../Icons/ExportIcon'
import QRCode from 'react-qr-code'
import CopyIcon from '../../Icons/CopyIcon'
import React from 'react'

export default function QRCard({ zoneId, room }: { zoneId: string; room: string }) {
  const url = `https://stg.mobile.pura.com/guestControl?zone_id=${zoneId}&brand=tommy_bahama`

  const [openToast, setOpenToast] = React.useState(false)
  const handleClick = () => {
    navigator.clipboard.writeText(url)
    setOpenToast(true)
  }
  const handleClose = () => {
    setOpenToast(false)
  }
  return (
    <Box
      p={'16px'}
      sx={{
        backgroundColor: '#fff',
        borderRadius: '16px',
        fontFamily: 'Apercu',
        position: 'relative',
        height: '100%',
      }}>
      <Typography
        variant="h2"
        sx={{
          color: '#000',
          fontSize: '14px',
          fontWeight: 'bold',
          lineHeight: '24px',
          textAlign: 'left',
          fontFamily: 'Apercu',
          display: 'flex',
          justifyContent: 'space-between',
        }}>
        App QR code - {room}
        <ExportIcon />
      </Typography>

      <div style={{ height: 'auto', maxWidth: 108, width: '100%', marginTop: '16px' }}>
        <QRCode
          size={256}
          style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
          value={url}
        />
      </div>

      <Typography
        variant="h2"
        mt={'33px'}
        sx={{
          color: '#000',
          fontSize: '14px',
          fontWeight: 'bold',
          lineHeight: '24px',
          textAlign: 'left',
          fontFamily: 'Apercu',
          display: 'flex',
          justifyContent: 'space-between',
        }}>
        App URL - {room}
        <span
          onClick={handleClick}
          style={{
            cursor: 'pointer',
          }}>
          <CopyIcon />
        </span>
      </Typography>
      <Box
        p={'12px'}
        sx={{
          backgroundColor: '#F7F6F6',
          overflow: 'hidden',
          borderRadius: '8px',
          fontSize: '14px',
          color: '#74716F',
          textWrap: 'nowrap',
        }}>
        {url}
      </Box>
      <Snackbar
        open={openToast}
        onClose={handleClose}
        message="Copied to clipboard"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        key={'fade'}
        autoHideDuration={700}
        TransitionComponent={Fade}
        sx={{
          position: 'absolute',
          bottom: '16px',
        }}
      />
    </Box>
  )
}
