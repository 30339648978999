import { Button, ClickAwayListener, Grow, ListItemIcon, ListItemText, Menu, MenuItem, MenuList, Paper, Popper, Snackbar, Stack  } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import React from 'react';
import StopIcon from '../Icons/StopIcon';
import PlayIcon from '../Icons/PlayIcon';
import CalendarIcon from '../Icons/CalendarIcon';
import EditCalendarIcon from '../Icons/EditCalendarIcon';
import LightIcon from '../Icons/LightIcon';
import NoLightIcon from '../Icons/NoLightIcon';
import { useGroupTurnOn } from '../mutations';

export type lineItems = {
    id: number,
    title: string,
}

export default function ControlMenu({active, items} : {active: boolean, items: number[]} ) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const groupTurnOnMutation = useGroupTurnOn()

  const [openToast, setOpenToast] = React.useState(false);
  const [toastMessage, setToastMessage] = React.useState('');
  const handleOpenToast = () => {
    setOpenToast(true);
  }
  const handleCloseToast = () => {
    setOpenToast(false);
  };

  const handleStartDevices = ({bay}: {bay: number}) => {
      handleClose()
      const deviceIDs = items.map((item) => item.toString())
      groupTurnOnMutation.mutate(
        {
          deviceIds: deviceIDs,
          action: {
            name: 'on', 
            intensity: 'medium', 
            bay: bay,
          },
        },
        {
            onSuccess: () => {
              handleOpenToast()
              setToastMessage('Devices turned on')
            },
            onError: () => {
              handleOpenToast()
              setToastMessage('Failed to turn on devices')
            },
        }
      )
  }

  const handleStopDevices = () => {
    handleClose()
    const deviceIDs = items.map((item) => item.toString())
    groupTurnOnMutation.mutate(
      {
        deviceIds: deviceIDs,
        action: {
          name: 'off', 
          bay: 0,
        },
      },
      {
          onSuccess: () => {
            handleOpenToast()
            setToastMessage('Devices turned off')
          },
          onError: () => {
            handleOpenToast()
            setToastMessage('Failed to turn off devices')
          },
      }
    )
  }
  
  return (
    <div className='control-button-container'>
      <Button
        id="demo-positioned-button"
        aria-controls={open ? 'demo-positioned-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{
          backgroundColor: active ? '#4D7737' : '#CEC6C2',
          borderRadius: '100px',
          color: '#fff',
          fontWeight: 'bold',
          padding: '10px 16px',
        }}
        endIcon={<KeyboardArrowDownIcon />}
        disabled = {!active}
      >
        <span className='control-button-text'>
            Control {active && `(${items.length})` }
        </span>
      </Button>
      <Menu
        id="customized-menu"
        aria-labelledby="customized-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem onClick={() => handleStopDevices()}>
          <ListItemIcon>
            <StopIcon />
          </ListItemIcon>
          <ListItemText>Stop</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => handleStartDevices({bay: 1})}>
          <ListItemIcon>
            <PlayIcon />
          </ListItemIcon>
          <ListItemText>Start Bay 1</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => handleStartDevices({bay: 2})}>
          <ListItemIcon>
              <PlayIcon />
          </ListItemIcon>
          <ListItemText>Start Bay 2</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <CalendarIcon />
          </ListItemIcon>
          <ListItemText>Create a Schedule</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
          <EditCalendarIcon />
          </ListItemIcon>
          <ListItemText>Edit a Schedule</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <LightIcon />
          </ListItemIcon>
          <ListItemText>Light On</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <NoLightIcon />
          </ListItemIcon>
          <ListItemText>Light Off</ListItemText>
        </MenuItem>
      </Menu>
      <Snackbar
        open={openToast}
        onClose={handleCloseToast}
        message={toastMessage}
        anchorOrigin={{  vertical: 'bottom', horizontal: 'center' }}
        key={'fade'}
        autoHideDuration={2000}
      />
    </div>
  );
}