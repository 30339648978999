import Box from '@mui/material/Box'
import { Typography } from '@mui/material'
import SignalStrong from '../../Icons/SignalStrong'

export default function DiffuserInfo({
  macAddress,
  hWVersion,
  fWVersion,
}: {
  macAddress: string
  hWVersion: string
  fWVersion: string
}) {
  return (
    <Box
      p={'16px'}
      sx={{
        backgroundColor: '#fff',
        borderRadius: '16px',
        fontFamily: 'Apercu',
        height: '50%',
      }}>
      <Typography
        variant="h2"
        sx={{
          color: '#000',
          fontSize: '14px',
          fontWeight: 'bold',
          lineHeight: '24px',
          textAlign: 'left',
          fontFamily: 'Apercu',
        }}>
        Diffuser info
      </Typography>
      <Typography
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          color: '#4F4C4A',
          textTransform: 'capitalize',
          alignItems: 'center',
          marginTop: '8px',
        }}>
        <span>MAC Address:</span>
        <span style={{ color: '#74716F' }}>{macAddress}</span>
      </Typography>
      <Typography
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          color: '#4F4C4A',
          textTransform: 'capitalize',
          alignItems: 'center',
          marginTop: '8px',
        }}>
        <span>HW version:</span>
        <span style={{ color: '#74716F' }}>{hWVersion}</span>
      </Typography>
      <Typography
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          color: '#4F4C4A',
          textTransform: 'capitalize',
          alignItems: 'center',
          marginTop: '8px',
        }}>
        <span>FW version:</span>
        <span style={{ color: '#74716F' }}>{fWVersion}</span>
      </Typography>
    </Box>
  )
}
